import * as wasm from './radix_bg.wasm';

const lTextDecoder = typeof TextDecoder === 'undefined' ? (0, module.require)('util').TextDecoder : TextDecoder;

let cachedTextDecoder = new lTextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachegetUint8Memory0 = null;
function getUint8Memory0() {
    if (cachegetUint8Memory0 === null || cachegetUint8Memory0.buffer !== wasm.memory.buffer) {
        cachegetUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachegetUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

let WASM_VECTOR_LEN = 0;

const lTextEncoder = typeof TextEncoder === 'undefined' ? (0, module.require)('util').TextEncoder : TextEncoder;

let cachedTextEncoder = new lTextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length);
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len);

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3);
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

let cachegetInt32Memory0 = null;
function getInt32Memory0() {
    if (cachegetInt32Memory0 === null || cachegetInt32Memory0.buffer !== wasm.memory.buffer) {
        cachegetInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachegetInt32Memory0;
}
/**
* @param {number} large
* @param {number} base
* @returns {string}
*/
export function decimal_to_radix(large, base) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.decimal_to_radix(retptr, large, base);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        return getStringFromWasm0(r0, r1);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
        wasm.__wbindgen_free(r0, r1);
    }
}

/**
* @param {string} rad_val
* @param {number} base
* @returns {number}
*/
export function radix_to_decimal(rad_val, base) {
    var ptr0 = passStringToWasm0(rad_val, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    var ret = wasm.radix_to_decimal(ptr0, len0, base);
    return ret;
}

/**
* @param {number} numer
* @param {number} denom
* @param {number} base
* @returns {string}
*/
export function fraction_to_unit(numer, denom, base) {
    try {
        const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
        wasm.fraction_to_unit(retptr, numer, denom, base);
        var r0 = getInt32Memory0()[retptr / 4 + 0];
        var r1 = getInt32Memory0()[retptr / 4 + 1];
        return getStringFromWasm0(r0, r1);
    } finally {
        wasm.__wbindgen_add_to_stack_pointer(16);
        wasm.__wbindgen_free(r0, r1);
    }
}

/**
* @param {string} num_string
* @param {number} base
* @returns {NumString}
*/
export function radix_fraction_to_radix(num_string, base) {
    var ptr0 = passStringToWasm0(num_string, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    var ret = wasm.radix_fraction_to_radix(ptr0, len0, base);
    return NumString.__wrap(ret);
}

/**
* @param {number} dec_val
* @param {number} precision
* @returns {Fraction}
*/
export function float_to_fraction(dec_val, precision) {
    var ret = wasm.float_to_fraction(dec_val, precision);
    return Fraction.__wrap(ret);
}

/**
*/
export class Fraction {

    static __wrap(ptr) {
        const obj = Object.create(Fraction.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_fraction_free(ptr);
    }
    /**
    * @param {number} numer
    * @param {number} denom
    * @param {number} diff
    * @returns {Fraction}
    */
    static new(numer, denom, diff) {
        var ret = wasm.fraction_new(numer, denom, diff);
        return Fraction.__wrap(ret);
    }
    /**
    * @returns {number}
    */
    numerator() {
        var ret = wasm.fraction_numerator(this.ptr);
        return ret;
    }
    /**
    * @returns {number}
    */
    denominator() {
        var ret = wasm.fraction_denominator(this.ptr);
        return ret;
    }
    /**
    * @returns {number}
    */
    difference() {
        var ret = wasm.fraction_difference(this.ptr);
        return ret;
    }
}
/**
*/
export class NumString {

    static __wrap(ptr) {
        const obj = Object.create(NumString.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_numstring_free(ptr);
    }
    /**
    * @param {number} num
    * @param {string} text
    * @returns {NumString}
    */
    static new(num, text) {
        var ptr0 = passStringToWasm0(text, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len0 = WASM_VECTOR_LEN;
        var ret = wasm.numstring_new(num, ptr0, len0);
        return NumString.__wrap(ret);
    }
    /**
    * @returns {number}
    */
    as_float() {
        var ret = wasm.fraction_difference(this.ptr);
        return ret;
    }
    /**
    * @returns {string}
    */
    as_string() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.numstring_as_string(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(r0, r1);
        }
    }
}

export const __wbindgen_throw = function(arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
};

